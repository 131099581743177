<template>
  <div class="account-detail-max-wrap">
    <div class="account-wrap">
      <div class="account-card-wrap">
        <div class="account-card-box por dfc flex-center por">
          <div class="point-bg poa">
            <img class="w100 h100" src="../assets/images/point-bg.png" alt="" />
          </div>
          <!-- 历史提现 -->
          <!-- 主持人 -->
          <div
            @click="go('withdrawalHistory')"
            class="top-right fs24 fcfff dfr flex-center "
            v-if="type == 1"
          >
            <div class="icon-box fs0">
              <img
                class="w100 h100"
                src="../assets/images/account-icon.png"
                alt=""
              />
            </div>
            <div class="op9">历史提现</div>
          </div>

          <!-- 账户积分余额 -->
          <div class="top fs28 w100 text-center dfr flex-center">
            <div class="top-left fs24  op9 ">账户积分余额</div>
          </div>
          <!-- 价格 -->
          <div class="price ">{{ price | formatCurrency }}</div>
          <!-- 主持人 -->
          <div v-if="type == 1">
            <icy-button
              @click.native="go('withdrawal')"
              width="2rem"
              height=".64rem"
              fontSize=".28rem"
              color="#7C5A2F"
              background="#fff"
              borderRadius=".44rem"
              boxShadow="0 .04rem .12rem 0 rgba(203, 144, 37, 0.4)"
              >提现</icy-button
            >
          </div>
          <!-- 私董 -->
          <div v-else>
            <icy-button
              @click.native="go('charge')"
              width="2rem"
              height=".64rem"
              fontSize=".28rem"
              color="#7C5A2F"
              background="#fff"
              borderRadius=".44rem"
              boxShadow="0 .04rem .12rem 0 rgba(203, 144, 37, 0.4)"
              >充值</icy-button
            >
          </div>
        </div>
      </div>
      <!-- 流水 -->
      <div class="flowList-wrap">
        <div class="order-line-box "></div>
        <!-- tab -->
        <div class="title-wrap  ">
          <!-- tabbar -->
          <div class="tabbar ">
            <van-tabs
              sticky
              v-model="activedIndex"
              line-width="30px"
              swipeable
              color="#FABC00"
            >
              <van-tab
                v-for="(item, index) in tabList"
                :key="index"
                :title="item.name"
              >
                <account-list :type="item.type"></account-list>
              </van-tab>
            </van-tabs>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      price: 0,
      flowList: [],
      type: "",
      activedIndex: 0,
      // 1私董充值  2私董参会增加  3私董开会扣减  4主持人参会获得  5提现扣减
      tabList: [],
      moderatorTabs: [
        {
          name: `全部流水`,
          type: "",
          showNum: false
        },
        {
          name: "参会获得",
          type: "4",
          showNum: false,
          num: 0
        },
        {
          name: "提现扣减",
          type: "5",
          showNum: false,
          num: 0
        }
      ],
      sidongTabs: [
        {
          name: `全部流水`,
          type: "",
          showNum: false
        },
        {
          name: "充值增加",
          type: "1",
          showNum: false,
          num: 0
        },
        {
          name: "参会增加",
          type: "2",
          showNum: false,
          num: 0
        },
        {
          name: "开会扣减",
          type: "3",
          showNum: false,
          num: 0
        }
      ]
    };
  },
  created() {
    this.$title("我的积分");
    this.initPage();
  },
  mounted() {},
  methods: {
    async initUser() {
      const resp = await this.$API.post("userInfo").then(resp => resp);
      if (resp.code == 1000) {
        this.price = resp.data.info.balance;
        // type 1 主持人  0私董
        if (resp.data.info.type == 1) {
          this.tabList = this.moderatorTabs;
        } else {
          this.tabList = this.sidongTabs;
        }
        this.type = resp.data.info.type;
      }
    },
    async initPage() {
      this.initUser();
    },
    go(pathname) {
      this.$go(pathname);
    }
  },
  destroyed() {}
};
</script>
<style lang="less" scoped>
.point-bg {
  width: 2.4rem;
  height: 1.7rem;
  right: 0;
  bottom: 0;
}
.account-detail-max-wrap {
  min-height: 100vh;
  background: #f7f7f7;
}
.top {
  padding-top: 0.2rem;
}
.top-right {
  position: absolute;
  right: 0.36rem;
  top: 0.36rem;
  color: #7c5a2f;
}
.top-left {
  color: #7c5a2f;
}
.empty {
  background: #fbfbfb;
}
.account-card-wrap {
  width: 100vw;
  box-sizing: border-box;
  background: #fff;
}
.account-card-box {
  width: 7.5rem;
  margin: 0 auto;
  background: linear-gradient(270deg, #e3b06b 0%, #fddc98 100%);
  padding: 0.36rem;
}
.icon-box {
  width: 0.2rem;
  height: 0.2rem;
  margin-right: 0.08rem;
}
.price {
  margin: 0 auto 0.4rem;
  color: #7c5a2f;
  font-size: 0.68rem;
}
.withdrawal-btn {
  width: 2rem;
  height: 0.64rem;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 0.04rem 0.2rem 0px rgba(0, 0, 0, 0.1);
  border-radius: 0.44rem;
  box-sizing: border-box;
  color: #fabc00;
}
.withdrawal-btn:active {
  opacity: 0.5;
}

.order-type-box .order-item {
  flex: 1;
  padding: 0.26rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.order-line-box {
  height: 1px;
  transform: scaleY(0.5);
  background: #eee;
  width: 100%;
}
.order-active {
  width: 0.6rem;
  height: 0.12rem;
  background: linear-gradient(
    90deg,
    rgba(250, 190, 0, 1) 0%,
    rgba(250, 134, 0, 1) 100%
  );
  box-shadow: 0px 0.02rem 0.04rem 0px rgba(250, 190, 0, 0.4);
  border-radius: 0.05rem;
  position: absolute;
  bottom: 0;
  left: 20%;
}
.flow-top-max-wrap {
  padding: 0.28rem 0.36rem 0;
}
.flow-box .time {
  padding: 0.18rem 0 0.28rem;
  border-bottom: 0.01rem solid #eee;
}
.flow-top-max-wrap:last-child .time {
  border: none;
}
.flow-box {
  border-bottom: 0.01rem solid #eee;
  background: #fff;
}
.flow-box .name {
  width: 4rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transform: translateY(0.05rem);
}
.flow-price {
  display: inline-block;
  transform: translateY(0.03rem);
}
</style>
